import React, { Component } from "react"
import styles from "./services.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

class Services extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: props.data.services[0],
      service: 0,
    }
  }

  render() {
    const { services, language } = this.props.data
    let currService = this.state.service

    const getService = (services, language) => {
      const service = services ? (
        language === "en" ? (
          <span>{services.service_en}</span>
        ) : (
          <span>{services.service_mt}</span>
        )
      ) : null
      return service ? service : null
    }
    const clickLeft = () => {
      currService = !currService ? services.length - 1 : currService - 1
      this.setState({
        service: currService,
        current: services[currService],
      })
    }
    const clickRight = () => {
      currService = currService === services.length - 1 ? 0 : currService + 1
      this.setState({
        service: currService,
        current: services[currService],
      })
    }

    return (
      <div className={styles.services}>
        <div className={styles.servicesMobile}>
          <FontAwesomeIcon
            onClick={clickLeft}
            className={`${styles.chevron} ${styles.chevronLeft}`}
            icon={faChevronLeft}
          />
          <span key={services[0].service_en}>
            <div className={`${styles.speechBubble} wow pulse`}>
              {getService(this.state.current, language)}
            </div>
          </span>
          <FontAwesomeIcon
            onClick={clickRight}
            className={`${styles.chevron} ${styles.chevronRight}`}
            icon={faChevronRight}
          />
        </div>

        <div className={`${styles.servicesDesktop} wow pulse`}>
          {services &&
            services.map(service => (
              <span
                className={`${styles.serviceWrapper}`}
                key={service.service_en}
              >
                {getService(service, language)}
              </span>
            ))}
        </div>
      </div>
    )
  }
}

export default Services
