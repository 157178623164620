import React from "react"
import PageContent from "../components/PageContent/pageContent"
import Services from "../components/PageContent/Services/services"

function HomePage(props) {
  const data = {
    ...props.data,
    uri: props.uri,
    language: props.language,
  }
  const servicesData = {
    services: data.wordpressPage.acf.services,
    language: props.language,
  }

  return (
    <div>
      <PageContent language={props.language} data={data} />
      <Services data={servicesData} />
    </div>
  )
}

export default HomePage

export const homePageQuery = graphql`
  query homePageQuery {
    wordpressPage(slug: { eq: "home" }) {
      id
      slug
      status
      title
      acf {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 3600) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        image_mobile {
          localFile {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        title_overlay {
          title
          title_mt
        }
        subtitle_overlay {
          subtitle
          subtitle_mt
        }
        headline_title_hm {
          title
          title_mt

          animated_words {
            words
          }
          animated_words_mt {
            words
          }
        }
        headline_subtitle_hm {
          add_subtitle
          subtitle
          subtitle_mt
        }
        headline_content_hm {
          add_content
          content
          content_mt
        }
        headline_image_hm {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        headline_icon_hm {
          add_icon
          icon {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        services {
          service_en
          service_mt
        }
        content_block {
          title {
            add_title
            title
            title_mt
          }
          subtitle {
            add_subtitle
            subtitle
            subtitle_mt
          }
          image {
            align_image
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
          icon {
            add_icon
            align_icon
            icon {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
          content {
            add_content
            content
            content_mt
          }
        }
      }
    }
  }
`
